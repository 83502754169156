import * as types from './types';
import { LOGOUT, LOGIN_SUCCESS } from '../access/types';
import {
  GET_LEAGUE_TRANSACTIONS_SUCCESS,
  GET_TEAM_TRADE_REQUESTS,
  GET_TEAM_TRADE_REQUESTS_SUCCESS,
} from '../transactions/types';

const initialState = {
  myTeams: [],
  currentTeam: {},
  leagueChatHistory: [],
  leagueMatches: [],
  leagueCalendar: [],
  userMatches: [],
  leagueMatchups: [],
  leagueTransactions: [],
  teamTradeRequests: { requests: [], team: null },
  currentTradeRequest: {},
  currentPlayer: {},
  publicLeagues: [],
  guests: [],
  commisioner: '',
};

export default (state = initialState, action) => {
  let newArr;
  switch (action.type) {
    case types.SET_CURRENT_PLAYER:
      return {
        ...state,
        currentPlayer: action.payload,
      };
    case types.SET_CURRENT_TRADE_REQUEST:
      return {
        ...state,
        currentTradeRequest: action.payload,
      };
    case GET_TEAM_TRADE_REQUESTS:
      return {
        ...state,
        teamTradeRequests:
          action.payload !== state.teamTradeRequests
            ? { requests: [], team: null }
            : state.teamTradeRequests,
      };
    case GET_TEAM_TRADE_REQUESTS_SUCCESS:
      return {
        ...state,
        teamTradeRequests: action.payload,
      };
    case GET_LEAGUE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        leagueTransactions: action.payload,
      };
    case types.GET_LEAGUE_MATCHUPS:
      console.log(state.leagueMatchups);
      return {
        ...state,
        isLoadingMatchups: !(
          state.leagueMatchups &&
          state.leagueMatchups.length &&
          state.leagueMatchups[0].league_id === parseInt(action.leagueId, 10)
        ),
      };
    case types.GET_LEAGUE_MATCHUPS_ERROR:
      return {
        ...state,
        leagueMatchups: [],
        isLoadingMatchups: false,
      };
    case types.GET_LEAGUE_MATCHUPS_SUCCESS:
      return {
        ...state,
        leagueMatchups: action.payload,
        isLoadingMatchups: false,
      };
    case types.GET_MY_MATCHES_SUCCESS:
      return {
        ...state,
        userMatches: action.payload,
      };
    case types.GET_TOURNAMENT_STAGE_SUCCESS:
      return {
        ...state,
        tournamentStage: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentInvitation: null,
      };
    case LOGOUT:
      return initialState;
    case types.GET_LEAGUE_CHAT_HISTORY:
      return {
        ...state,
        leagueChatHistory: action.payload,
      };
    case types.GET_LEAGUE_MATCHES_SUCCESS:
      return {
        ...state,
        leagueMatches: action.payload,
      };
    case types.GET_LEAGUE_CALENDAR_SUCCESS:
      return {
        ...state,
        leagueCalendar: action.payload,
      };
    case types.APPEND_CHAT_MESSAGE:
      newArr = [...state.leagueChatHistory];
      newArr.push(action.payload);
      return {
        ...state,
        leagueChatHistory: newArr,
      };
    case types.GET_CURRENT_INVITATION_SUCCESS:
      return {
        ...state,
        currentInvitation: action.payload,
      };
    case types.GET_CURRENT_LEAGUE:
      return {
        ...state,
        isLoadingLeague:
          !state.currentLeague ||
          parseInt(action.leagueId, 10) !== state.currentLeague.id ||
          state.currentLeague.league_teams === undefined,
      };
    case types.SET_CURRENT_LEAGUE:
      return {
        ...state,
        currentLeague: action.payload,
        isLoadingLeague: false,
      };
    case types.GET_CURRENT_LEAGUE_ERROR:
      return {
        ...state,
        isLoadingLeague: false,
      };
    case types.GET_LEAGUES_ADMIN:
      return {
        ...state,
        leaguesAdmin: action.payload,
      };
    case types.SET_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.payload,
        currentTeamStarters: action.currentTeamStarters,
      };
    case types.SET_CURRENT_TEAM_PLAYERS:
      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          league_team_player: action.payload,
        },
        currentTeamStarters: action.currentTeamStarters,
      };
    case types.GET_MY_TEAMS_SUCCESS:
      return {
        ...state,
        myTeams: action.payload,
      };
    case types.GET_ALIGNMENTS_SUCCESS:
      return {
        ...state,
        alignments: action.payload,
      };
    case types.GET_PUBLIC_LEAGUES:
      return {
        ...state,
        publicLeagues: action.payload,
      };
    case types.SET_GUESTS:
      return {
        ...state,
        guests: action.payload,
      };
    case types.SET_COMMISIONER:
      return {
        ...state,
        commisioner: action.payload,
      };
    case types.GET_PLAYERS_FROM_TEAM:
      return {
        ...state,
        playersFromTeam: action.payload,
      };
    default:
      return state;
  }
};
