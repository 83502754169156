const en = {
  cannotContainOnlyNumbers: 'It cannot contain only numbers',
  'Add New': 'Add New',
  AddPlayer: 'Add player',
  'Added players': 'Added players',
  'Africa/Central': 'Africa/Central',
  'African/East': 'African/East',
  'All Messages': 'All Messages',
  'All Transactions': 'All Transactions',
  'and see why thousands are moving their leagues to joga':
    'and see why thousands are moving their leagues to joga',
  AddPlayerToFavoritesError:
    'This player is no longer participating in La Liga.',
  Advertising: 'Advertising',
  Alignment: 'Alignment',
  'Australia/Center': 'Australia/Center',
  'Australia/East': 'Australia/East',
  AlreadyJoinedError: 'You have already been invited to this league.',
  Bad: 'Bad',
  Birthdate: 'Birthdate',
  'Brazil/East': 'Brazil/East',
  Browse: 'Browse',
  'Bulk upload': 'Bulk upload',
  Calendar: 'Calendar',
  'Canada/Newfoundland': 'Canada/Newfoundland',
  'Central USA and Canada': 'Central USA and Canada',
  'Check your email inbox "{{email}}" to complete the registration!':
    'Check your email inbox "{{email}}" to complete the registration!',
  'Check your email inbox': 'Check your email inbox',
  'Change Time Entry': 'Change Time Entry',
  CardAboutJoga: {
    Title1: 'Create or join a league',
    Title2: 'Draft your team',
    Title3: 'Manage your team',
    Title4: 'Follow your matches',
    Image1: '../../assets/images/movil_1_en.png',
    Image2: '../../assets/images/movil_2_en.png',
    Image3: '../../assets/images/movil_3_en.png',
    Image4: '../../assets/images/movil_4_en.png',
  },
  CardArticleJoga: {
    Title1: '',
    Title2: '',
    Title3: '',
    Description1:
      'Fantasy is an experience that will change the way you live and see football. We tell you all the details you need to know',
    Description2:
      'Obtaining the title will not be easy, the assembly of your team will be a key piece to get the glory. For this reason we have created this guide with everything you need to know about the joga draft system',
    Description3:
      'After the draft, it’s time to manage your team. We give you recommendations on how to choose the best team in your league.',
    TextButton: 'Read more',
  },
  idealTeamTitle: 'Team of the week ',
  idealTeamOfSeasonTitle: 'Team of the season ',
  idealTeamMvp: 'MVP',
  PerformanceInformationTitle: "Who's hot",
  RecommendedPlayersTitle: 'Recommendations',
  Classification: 'Classification',
  Closing: 'Closing',
  ConfirmPassword: 'Confirm Password',
  Contact: 'Contact',
  Country: 'Country',
  'Create a team': 'Create a team',
  CreateLeague: 'Create League',
  'Create One Now': 'Create One Now',
  CreateTeam: 'Create Team',
  CreateTeamError:
    "You can't create a team in this league. The league has already started.",
  CreateTeamGenericError: 'There was an error creating your team.',
  CreateTeamTeamExistsError: 'You already have a team in this league.',
  CreateTeamTeamMaxQuantityError: 'This league is already full',
  Created: 'Created',
  'Coordinated Universal Time': 'Coordinated Universal Time',
  'Copied to the clipboard': 'Copied to the clipboard',
  Copy: 'Copy',
  'Draft Date': 'Draft Date',
  'Dropped players': 'Dropped players',
  EditLeague: 'Edit League',
  EditTeam: 'Edit Team',
  EditSuccessfully: 'Edited successfully',
  Edit: 'Edit',
  ExtraBox: 'Extra box',
  'Egypt/Arabic': 'Egypt/Arabic',
  Email: 'Email',
  EmailOrUsername: 'Email or username',
  'Email Address': 'Email Address',
  English: 'English',
  EnterDraft: 'Enter draft',
  'Enter date': 'Enter date',
  EnterAnEmailToInvite:
    'League users (Enter email address to invite new users)',
  UserInvite: 'Invited user',
  'Enter invitation code': 'Enter invitation code',
  'Enter your email': 'Enter your email',
  'Europe/Central': 'Europe/Central',
  'Europe/Eastern': 'Europe/Eastern',
  ExperienceTheThrillOfALive: 'Experience The Thrill Of A Live',
  Height: 'Height',
  Weight: 'Weight',
  PlaceOfBorn: 'Place of birth',
  FantasyDraft: 'Fantasy Draft',
  ExperienceTheThrillOfALiveDraftToSeeWhyThousandsAreMovingTheirLeaguesToJoga:
    'Enjoy',
  ExperienceTheThrillOfALiveDraftToSeeWhyThousandsAreMovingTheirLeaguesToJogaEnd:
    'like you have never done before',
  FavoriteTeam: 'Favorite Team',
  FantasyPointsPerGame: 'Fantasy points per game',
  FantasyPoints: 'Fantasy points',
  Firstnames: 'Firstnames',
  ForgotYourPassword: 'Forgot your password?',
  'Greenwich Mean Time': 'Greenwich Mean Time',
  Hawaii: 'Hawaii',
  'Here you can edit league settings': 'Here you can edit league settings',
  'Indiana/East': 'Indiana/East',
  'Invite players': 'Invite players',
  Japan: 'Japan',
  'joga fantasy Successful registration!':
    'joga fantasy Successful registration!',
  JoinALeague: 'Join A League',
  JoinPublicLeagueError:
    "You can't join this league. The league has already started.",
  JoinPrivateLeagueError:
    "You can't join this league. The league has already started.",
  JoinedToLeagueMessage: 'You have joined to the league',
  'Join to league': 'Join to league',
  YourAccountHasBeenConfirmed: 'Your account has been confirmed',
  Language: 'Language',
  Lastnames: 'Lastnames',
  'Last Post': 'Last Post',
  League: 'League',
  NoLeaguesMessage: 'You are not participating in any league.',
  'League Name': 'League Name',
  CreatedSuccessfully: 'Created successfully',
  InternalErrorMessage: 'Sorry, an internal error has occurred',
  Restart: 'Restart',
  Login: 'Login',
  Logout: 'Logout',
  loginSuccessfully: 'You have logged in successfully',
  Match: 'Match',
  Matchups: 'Matchups',
  'Match of the Week': 'Match of the Week',
  Messages: 'Messages',
  'Middle East': 'Middle East',
  'Midway Islands': 'Midway Islands',
  'My League': 'My League',
  MyTeam: 'My Team',
  'Near East': 'Near East',
  'New Zealand': 'New Zealand',
  Pacific: 'Pacific',
  Passes: 'Passes',
  Password: 'Password',
  Phoenix: 'Phoenix',
  'Pick a date': 'Pick a date',
  Leagues: 'Leagues',
  Player: 'Player',
  Players: 'Players',
  PlayersShown: 'Players shown',
  PositionsTable: 'Positions Table',
  processing: 'processing',
  Profile: 'Profile',
  Post: 'Post',
  'Puerto Rico and US Virgin Islands': 'Puerto Rico and US Virgin Islands',
  Questions: 'Questions',
  Rank: 'Rank',
  'Ready to Start Your Own League?': 'Ready to Start Your Own League?',
  RecoverPassword: 'Recover Password',
  'Reset password': 'Reset password',
  Report: 'Report',
  'Resend activation mail': 'Resend activation mail',
  'Rocky Mountains': 'Rocky Mountains',
  Rules: 'Rules',
  RulesUrl: '/notice-details/89',
  Saves: 'Saves',
  Score: 'Goals',
  Scorers: 'Scorers',
  Scouting: 'Scouting',
  Shortcuts: 'Shortcuts',
  ShortcutsBlocksClear: 'Shortcuts, blocks and clearances',
  'Select a Shield': 'Select a Shield',
  SelectPicture: 'Select picture',
  'Select your country': 'Select your country',
  'Select your favorite team': 'Select your favorite team',
  'Select your timezone': 'Select your timezone',
  Send: 'Send',
  'Send recover password mail': 'Send recover password mail',
  'Session was close, come back soon!': 'Session was close, come back soon!',
  Setting: 'Settings',
  SignUp: 'Sign Up',
  'Sign Up Now': 'Sign Up Now',
  'Solomon Islands': 'Solomon Islands',
  Spanish: 'Spanish',
  Sponsors: 'Sponsors',
  Standings: 'Standings',
  Statistics: 'Statistics',
  Shooter: 'Shooter leg',
  'Success! A reset link was sent to your email. You have 24 hours to reset your password!':
    'Success! A reset link was sent to your email. You have 24 hours to reset your password!',
  'Success! Your new password was sent to your email!':
    'Success! Your new password was sent to your email!',
  Team: 'Team',
  Teams: 'Teams',
  'Team Name': 'Team Name',
  'Team Positions': 'Team Positions',
  'The birthdate is required': 'The birthdate is required',
  'The code is required': 'The code is required',
  'The country is required': 'The country is required',
  'The date is required': 'The date is required',
  'The date must be a valid date': 'The date must be a valid date',
  'The email confirmation was successful sended!':
    'The email confirmation was successful sended!',
  'The email field must be a valid email direction':
    'The email field must be a valid email direction',
  'The email field must be max 20 characters long':
    'The email field must be max 20 characters long',
  'The email is required': 'The email is required',
  'The favorite team is required': 'The favorite team is required',
  'The firstnames are required': 'The firstnames are required',
  'The firstnames field must be at least 4 characters long':
    'The firstnames field must be at least 4 characters long',
  'The firstnames field must be max 20 characters long':
    'The firstnames field must be max 20 characters long',
  'The firstnames field must be numbers and letters characters':
    'The firstnames field must be numbers and letters characters',
  TheGame: 'The Game',
  'The lastnames are required': 'The lastnames are required',
  'The lastnames field must be at least 4 characters long':
    'The lastnames field must be at least 4 characters long',
  'The lastnames field must be max 20 characters long':
    'The lastnames field must be max 20 characters long',
  'The lastnames field must be numbers and letters characters':
    'The lastnames field must be numbers and letters characters',
  'The league was successful edited!': 'The league was successful edited!',
  'The members must have a valid email address':
    'The members must have a valid email address',
  'The name field must be at least 4 characters long':
    'The name field must be at least 4 characters long',
  'The name field must be max 20 characters long':
    'The name field must be max 20 characters long',
  'The name is required': 'The name is required',
  'The new password field must be at least 6 characters long':
    'The new password field must be at least 6 characters long',
  'The new password field must be max 20 characters long':
    'The new password field must be max 20 characters long',
  'The new password is required': 'The new password is required',
  'The password confirmation is required':
    'The password confirmation is required',
  'The password confirmation must be equal to password field':
    'The password confirmation must be equal to password field',
  'The password field must be at least 6 characters long':
    'The password field must be at least 6 characters long',
  'The password field must be max 20 characters long':
    'The password field must be max 20 characters long',
  'The password is required': 'The password is required',
  'The team name is required': 'The team name is required',
  'The time is required': 'The time is required',
  'The timezone is required': 'The timezone is required',
  'The username is required': 'The username is required',
  'The username field must be at least 4 characters long':
    'The username field must be at least 4 characters long',
  'The username field must be max 20 characters long':
    'The username field must be max 20 characters long',
  'The username or email is required': 'The username or email is required',
  "This user isn't active, do you want to resend activation mail":
    "This user isn't active, do you want to resend activation mail",
  "You can't create a team in this league. Max quantity players already reached or the draft has already been done.":
    'You cant create a team in this league. Max quantity players already reached or the draft has already been completed.',
  'Unable to add players who are not in the current tournament':
    'This player is no longer participating in La Liga.',
  Time: 'Time',
  TimeZone: 'Time Zone',
  Trades: 'Trades',
  Transactions: 'Transactions',
  'Update league': 'Update league',
  'USA/Eastern': 'USA/Eastern',
  TooYoungToRegister: 'You must be 16 years or older to register',
  Username: 'Username',
  'Username or email': 'Username or email',
  'View Staff': 'View Staff',
  'Waiver Bids': 'Waiver Bids',
  'Welcome back': 'Welcome back',
  WelcomeToJogaFantasy: 'Welcome to joga fantasy:',
  WriteAMessage: 'Write a message',
  YouCanEditLeagueSettingsLater: 'You can edit league settings later',
  YouCanEditTeamSettingsLater: 'You can edit team settings later',
  'Your league was successful registered. Create your team now!':
    'Your league was successful registered. Create your team now!',
  'Your team was successful created!': 'Your team was successful created!',
  Name: 'Name',
  Surname: 'Lastname',
  NewPassword: 'New Password',
  RepeatYourNewPassword: 'Repeat your new password',
  ActivatedAccount: 'Activated Account',
  Success: 'Success',
  PitchView: ' Pitch View',
  MyTeamName: 'My Team Name',
  Alternate: 'Alternate',
  ReadyToStartYourOwnLeague: 'Ready to Start Your Own League?',
  CreateOneNow: 'Create One Now',
  DraftDate: 'Draft Date',
  SelectTimeZone: 'Select time zone',
  Create: 'Create',
  View: 'View',
  Defenders: 'Defenders',
  Defender: 'Defender',
  Positions: 'Positions',
  Search: 'Search',
  PlayerName: 'Player name',
  Pts: 'Points',
  Matches: 'Matches',
  PlayedMatches: 'Played Matches',
  Finished: 'Finished',
  LastPost: 'Last Post',
  NoTeamMessage: 'You do not have any team',
  NoMatchesMessage: 'You do not have any pending match',
  ReturnTopics: ' Return to topic list',
  Opponent: 'Opponent',
  Result: 'Result',
  News: 'News',
  position: 'Position',
  previous: 'Previous',
  next: 'Next',
  createTeamForLeague: 'Create team for {0}',
  noResults: 'There is no results',
  draftStartsIn: 'The draft starts in',
  turnEndsIn: 'The turn ends in:',
  draftStoppedMessage: 'Draft stopped',
  itsYourTurnMessage: "It's your turn",
  gamesPlayed: 'Games played',
  gamesStarted: 'Games started',
  goals: 'Goals',
  penaltyKickGoals: 'Penalty Kick Goals',
  save: 'Save',
  shotOnTarget: 'Shot on target',
  interceptions: 'Interceptions',
  tackleWon: 'Tackles won',
  aerialWon: 'Aerial Won',
  duelsWon: 'Duels Won',
  listEditor: 'List',
  paragraphEditor: 'Paragraph',
  titleEditor: 'Title',
  leftEditor: 'Left',
  centerEditor: 'Center',
  rightEditor: 'Right',
  leagueNoLogIn: 'Log in to see your leagues',
  teamsNoLogIn: 'Log in to see your teams',
  matchupNoLogIn: 'Log in to see your matchups',
  penaltyWon: 'Penalty Won',
  penaltyMissed: 'Saves inside the box ',
  saves_outside_box: 'Saves outside the boc ',
  high_claims: 'High Claims',
  punches: 'Punches',
  keeper_sweeps: 'Keeper Sweeps ',
  penalty_saved: 'PK Saved ',
  ball_recovery_gk: 'GK Smother (GkS)',
  keyPass: 'Key pass',
  hitWoodwork: 'Hit woodwork',
  clearances: 'Clearances',
  dispossession: 'Disposession',
  penaltySaved: 'Penalty saved',
  penaltyCommited: 'Penalty commited',
  yellowCard: 'Yellow card',
  redCard: 'Red card',
  accurateCross: 'Accurate cross',
  goalAgainst: 'Goals Conceded',
  error_lead_to_goal: 'Errors',
  penalty_won: 'PK Won',
  penalty_missed: 'PK Missed',
  penalty_committed: 'PK Conceded',
  clearances_head: 'Head Clearance',
  goalReceived: 'Goal received',
  goalAllowed: 'Goal allowed',
  goal_winning: 'Winning Goal',
  ownGoal: 'Own goal',
  special_assist: 'Special Assist ',
  big_chance_created: 'Big Chance Created',
  big_chance_missed: 'Big Chance Missed',
  cleanSheet: 'Clean sheets',
  secondAssist: 'Second assist',
  block: 'Blocks',
  blocked_cross: 'Blocked Cross',
  ground_duel_won: 'Duel Won',
  tackle_won: 'Tackle Won',
  ball_recovery: 'Ball Recovery',
  successful_dribbles: 'Succesful Dribbles',
  own_goal: 'Own Goal',
  saves: 'Saves',
  saves_inside_box: 'Saves inside the box',
  selectedPlayer: 'Selected',
  noPlayersSelected: 'No players selected',
  Start: 'Start',
  Cancel: 'Cancel',
  Goalkeepers: 'Gooalkeepers',
  Goalkeeper: 'Gooalkeeper',
  Midfielders: 'Midfielders',
  Midfielder: 'Midfielder',
  Attackers: 'Attackers',
  Attacker: 'Attacker',
  PlayersSelection: 'Players Selection',
  Selection: 'Selection',
  Position: 'Position',
  AllPositions: 'All positions',
  SortedBy: 'Sorted by',
  SearchByName: 'Search by name',
  TotalScores: 'Total scores',
  TotalMinutesPlayed: 'Minutes played',
  Appearances: 'Appearances',
  Notices: 'News',
  Delete: 'Delete',
  CreateNotice: 'Create Notice',
  Title: 'Title',
  NoticeType: 'Type',
  Description: 'Description',
  draftFinishedMessage: 'Draft finished',
  draftFinishedDescription:
    'The draft has finished, now you can go to the league details to transfer players with other team and another actions',
  draftUnfinishedMessage: 'Draft interrupted',
  draftUnfinishedNoTeamsDescription:
    'The draft was interrupted due to no teams connected',
  draftUnfinishedConnectionDescription:
    'The draft was interrupted due to connection problems with the teams',
  goToLeague: 'Go to league',
  fieldRequiredMessage: 'This field is required',
  example: 'ex: {0}',
  bench: 'Substitute',
  not_listed: 'Not summoned',
  titular: 'Starter',
  starters: 'Starters',
  playing: 'Playing',
  substitute_out: 'Substituted out',
  on_bench: 'On bench',
  stats: 'Stats',
  watch: 'Watch',
  substitute: 'Substitute',
  invalidDate: 'Invalid date',
  invalidHour: 'Invalid hour',
  CreateThread: 'Create thread',
  PublishMessage: 'Publish message',
  Publish: 'Publish',
  invalidEmail: 'Invalid email',
  invalidCode: 'Invalid code',
  invalidInvitationForUser:
    'The invitation does not belong to the current user',
  teamNameErrorMessage: 'The team name must have at least 2 characters',
  tradeExecutionMessage: 'This trade will be processed on the ',
  waiverExecutionMessage: 'This waiver request will be processed on the ',
  teamLogoErrorMessage: 'The team logo is required',
  ConnectionLost: 'Connection lost',
  ConnectionRecovered: 'Connection recovered',
  PlayerAlreadySelected: 'The player has been already selected',
  minutes_played: 'Minutes Played',
  finished_games: 'Finished Games',
  ConfirmEmailToPlay:
    'You must confirm your email address before accept this invitation',
  CleanSheets: 'Clean Sheets',
  Defense: 'Defense',
  SavesError: 'Error getting save table.',
  ScoreError: 'Error getting score table.',
  PassesError: 'Error getting passes table.',
  Page: 'Page',
  DefenseError: 'Error getting defense table.',
  CleanSheetsError: 'Error getting clean sheets table.',
  UserActiveAccount: 'This user is not activated!',
  UsernameError: 'The username must be greater than or equal to 3 characters',
  UsernameOrEmailError:
    'The username or email must be greater than or equal to 3 characters',
  PasswordError: 'The password must be between 6 and 32 characters',
  NameError: 'The name must be greater than or equal to 3 characters',
  SurnameError: 'The lastname must be greater than or equal to 3 characters',
  EmailError: 'You must enter a valid email',
  BirthdateError: 'You must enter your birthdate',
  FavoriteTeamError: 'You must select a team',
  GenderError: 'You must select a gender',
  signUpSuccess:
    'You have signed up successfully. We have sent an email to ‘{0}’ with instructions on how to activate your account. Click continue.',
  CountryError: 'You must select a country',
  TimeZoneError: 'You must select a time zone',
  Atencion: 'Attention',
  clickHere: 'click here',
  activationEmailTitle: 'Activation email',
  activationEmailButton: 'Send',
  notConfirm:
    'You have not confirmed your account. Please open our activation e-mail and click on the provided link to continue to enjoy joga fantasy.',
  notConfirmLineT:
    'If you have not received an email {0} to receive a new one.',
  notConfirmLineTr: '{0} The email may have ended up on your spam folder.',
  note: 'Note:',
  notAccount: 'Dont have an account? Register',
  emailDeUsuario: 'email de usuario',
  activationEmail:
    'A new activation email has been sent to ‘{0}’. Click on the provided link to activate your account and continue to enjoy joga fantasy.',
  RegisterSuccess:
    'An email has been sent with the instructions to activate your account!',
  RecoverySuccess:
    'An email has been sent with the instructions to recovery your account!',
  RecoveryConfirmSuccess: 'Your password has been successfully recovered!',
  ErrorMatchDetails: 'Error getting match details.',
  Resume: 'Resume',
  Gender: 'Gender',
  MatchStatistics: 'Match statistics',
  Posetion: 'Posetion',
  ShotsToGol: 'Shots to gol',
  MatchComments: 'Match comments',
  MatchInformation: 'Match information',
  Stadium: 'Stadium',
  Assistance: 'Assist',
  Substitutes: 'Substitutes',
  ArticlesError: 'Error getting the articles',
  PositionTableError: 'Error getting the table of positions',
  DefenderAbr: 'DF',
  AttackerAbr: 'FW',
  GoalkeeperAbr: 'GK',
  MidfielderAbr: 'MF',
  MatchPlayedAbr: 'MP',
  MatchWonAbr: 'MW',
  MatchLostAbr: 'ML',
  MatchTiedAbr: 'MT',
  None: 'None',
  CreateNoticeMessage: `The notice has been created successfully.`,
  CreateNoticeMessageErrorUpload: `An error occurred while loading the image of the notices.`,
  CreateNoticeMessageError: `An error occurred while creating the notices`,
  ImageError: 'You must select a image.',
  SuccessEditArticle: `The notice has been edited successfully.`,
  EditNoticeMessageError: `An error occurred while editing the notices`,
  SuccessDeleteArticle: `The notice has been deleted successfully.`,
  DeleteNoticeMessageError: `An error occurred while removing the notices.`,
  Accept: 'Accept',
  YouMustDelete: 'Do you want delete',
  ErrorTitle: 'The title must be greater than or equal to 3 caracteres',
  ErrorDescription:
    'The description must be greater than or equal to 20 caracteres',
  ErrorType: 'You must select a type.',
  Win: 'Win',
  Lost: 'Lost',
  Pending: 'Pending',
  noSubstitutesForPosition: 'You do not have substitutes for this position',
  SaveChanges: 'Save',
  Sending: 'Sending',
  InvalidPositionForPlayer: "You can't put this player in this position",
  Assists: 'Assists',
  LeagueStartsIn: 'Draft starts in {0}',
  Turn: 'Turn',
  CurrentTurn: 'Current Turn',
  Round: 'Round',
  selectionsForTurn: 'Selection for your turn',
  selectionsForTurnPlural: 'Selections for your turn',
  Posts: 'Posts',
  Favorites: 'Queue',
  SearchFilter: 'Search',
  Welcome: 'Welcome',
  Scores: 'Goals',
  SentBy: 'By',
  Copied: 'Copied',
  SessionExpiredMessage: 'Your session has expired, please sign in again',
  DraftInProgress: 'Draft in progress',
  SelectTeamShield: 'Select team shield',
  Load: 'Load',
  NotOnlyNumbersMessage: "It should'nt contain only numbers",
  CannotContainOnlyNumbersAndSpecialChars:
    'It cannot contain only numbers or special characters',
  CannotContainOnlySpecialChars: 'It cannot contain only special characters',
  UsernameOrEmailRequired: 'The field username or email is required',
  OffSides: 'Offsides',
  Corners: 'Corners',
  Fouls: 'Fouls',
  FilterByTeam: 'Filter by team',
  FilterByRealTeam: 'Filter by ',
  FilterByFantasyTeam: 'Filter by fantasy team',
  FilterByStages: 'Filter by season',
  FilterTeams: ' teams',
  FT: 'FT',
  NoStats: 'N/S',
  KP: 'KP',
  SOT: 'SOT',
  HW: 'HW',
  AC: 'AC',
  CS: 'CS',
  GA: 'GP',
  AEA: 'AeA',
  CLR: 'CLR',
  AFA: 'AFA',
  GR: 'GR',
  YC: 'YC',
  RC: 'RC',
  GP: 'GP',
  GS: 'GS',
  SV: 'SV',
  SD: 'SD',
  TK: 'TK',
  DIS: 'DIS',
  OG: 'OG',
  AW: 'AW',
  PKW: 'PKW',
  PKC: 'PKC',
  PKS: 'PKS',
  PKM: 'PKM',
  G: 'G',
  GG: 'WG',
  PU: 'PU',
  HC: 'HC',
  IntP: 'KS',
  A: 'A',
  PA: 'PK-S',
  R: 'GkS',
  RB: 'BR',
  AG: 'AW',
  DG: 'DW',
  E: 'E',
  PCO: 'PK-C',
  DTA: '2YC',
  PTS: 'Pts',
  'P/G': 'P/G',
  AE: 'AS',
  A2: 'A2',
  OGC: 'BCC',
  OGF: 'BCM',
  CE: 'AC',
  DE: 'SD',
  Int: 'Int',
  BL: 'BL',
  BLC: 'BL-C',
  DESC: 'CLR-H',
  TAP: 'SoP',
  GPY: 'P-PK',
  PR: 'PK-W',
  PF: 'PK-M',
  MP: 'MP',
  FPG: 'FP/P',
  FPTS: 'FPts',
  penalty_kick_goal: 'PK Goal',
  goalAgainstInMatches: '(OG)',
  goalInPenaltiesInMatches: '(PK)',
  points_per_game: 'Points per game',
  points_per_match: 'Points per match',
  AboutUs: 'About us',
  second_assists: 'Second Assist',
  accurate_cross: 'Accurate Cross',
  PrivacyPolicy: 'Privacy Policy',
  PrivacyPolicyUrl: '/notice-details/85',
  TermsAndConditions: 'Terms and conditions',
  TermsAndConditionsUrl: '/notice-details/87',
  second_yellow_card: 'Second Yellow Card',
  Week: 'Week',
  Weeks: 'Weeks',
  You: 'You',
  Subject: 'Subject',
  DraftResults: 'Draft results',
  Results: 'Results',
  WonAbr: 'W',
  LostAbr: 'L',
  DrawAbr: 'D',
  YourContactRequestWasSent: 'Your contact request was sent',
  Choosing: 'Choosing',
  LeavePlayerQuestion: 'Are you sure you want to leave the player free?',
  LeavingPlayerFree: 'Leaving the player free',
  AddPlayerQuestion: 'Are you sure you want to add this player to your team?',
  AddingPlayer: 'Adding player',
  LeagueStartOff: 'You cannot add a player before the beginning of the draft.',
  leagueIsNotActive: 'The league is not active',
  leagueTeamIsNotActive: "You can't add players before the draft begins",
  exchangeProposal:
    "You can't complete the trade proposal on {0} because his game on week {1} has already started.",
  addPlayers:
    "You can't add {0} to your team because his game on week {1} has already started",
  dropPlayers:
    "You can't drop {0} because his game on week {1} has already started.",
  changePlayersErr:
    'You cannot switch to {0} because his match of the day {1} has already started.',
  changePlayers: 'You have subbed {0} for {1} successfully.',
  is_on_another_team: 'That player has already been selected in this league.',
  DW: 'DW',
  gdabr: 'GD',
  Added: 'Added',
  ResetPsw: 'Reset password',
  emailLanguages: 'Email language',
  titleProfile: 'User Profile',
  filterbyWeek: 'Filter by week',
  allplayers: 'All players',
  availablePlayer: 'Available players',
  Dropped: 'Dropped',
  FreeAgent: 'From free agents',
  ToWaivers: 'To Waivers',
  FieldPlayers: 'Field players',
  Available: 'Available',
  MustBeOnlyLettersAndNumbers:
    'It must contain letters and numbers or only letters',
  TradePlayers: 'Trade players',
  Continue: 'Continue',
  YouMustSelectAPlayerToTrade: 'You must select at least a player to trade',
  YouMustSelectAPlayerToDelete: 'You must select at least a player to remove',
  TradeRequestSent: 'You have sent the exchange request succesfully',
  Trade: 'Trade',
  AllTeams: 'All teams',
  WaiverRequestSent: 'Waiver claim added',
  Building: 'Building',
  BuildingMessage: 'We are working in this section right now',
  WatchAll: 'Watch all',
  WeekAbr: 'W',
  Value: 'Value',
  WaiverBids: 'Waiver bids',
  Requested: 'Requested',
  RequestedFromWaivers: 'Requested from waivers',
  Add: 'Add',
  SelectPlayersToOffer: 'Select players to offer',
  SelectPlayerToDelete: 'Select a player to delete',
  SelectPlayersToDelete: 'Select players to delete',
  FromWaivers: 'From Waivers',
  ReplacePlayer: 'Replace player',
  TradeRequests: 'Trade requests',
  TradeRequestsFrom: 'You have received a trade request from',
  TradeRequestsTo: 'You have sent a trade request to',
  TradeRequestRejectedFrom: 'Your trade request was rejected by',
  TradeRequestExternalInProgress: '{0} and {1} have agreed to an exchange',
  TradeRequestRejectedTo: 'You have rejected a trade request from',
  TradeRequestInProgress: 'You have accepted a trade request from',
  TradeRequestsInProgresTo: 'Your trade request was accepted by',
  TradeRequestCancelled: 'Your trade request to {0} was cancelled',
  TradeRequestCancelledAdmin:
    'Your trade request to {0} was cancelled by the league Admin',
  TradeRequestReceivedCancelled: 'The trade request from {0} was cancelled',
  TradeRequestReceivedCancelledAdmin:
    'The trade request from {0} was cancelled by the league Admin',
  SeeDetails: 'See details',
  Confirm: 'Confirm',
  Rejected: 'Rejected',
  Approved: 'Approved',
  MatchesAbr: 'G',
  UploadImage: 'Upload image',
  Cancelled: 'Cancelled',
  OP: 'Opp',
  OPO: 'Opo',
  op: 'Opportunity',
  Opponet: 'Opponent',
  Today: 'Today',
  SuccessfullDribles: 'Successful dribbles',
  minPlayersPositionError:
    'The minimum quantity of players for the position {0} is {1}',
  maxPlayersPositionError:
    'The maximum quantity of players for the position {0} is {1}',
  InProgress: 'In progress',
  Completed: 'Completed',
  CancelAsLeagueAdmin: 'Cancel as league admin',
  ConfirmCancelAsAdmin:
    'Are you sure you want to cancel this trade request as league administrator?',
  CancelledByAdmin: 'Cancelled By Admin',
  Overview: 'Overview',
  PersonalDetails: 'Personal details',
  Nationality: 'Nationality',
  DateOfBirth: 'Date of birth',
  BancomerPlayingCareer: 'Bancomer playing career',
  Season: 'Season',
  SeasonAbbr: 'SZN',
  SelectPlayersToDemand: 'Select the players to demand',
  WaiverAbbr: 'WW',
  WaiverRequestPending: 'Waiver request add for {0}',
  WaiverRequests: 'Waiver requests',
  LeaveFreePlayersMessage:
    'You must leave free {0} player(s) to complete this exchange',
  onlyNumbersError: 'It must contain only numbers',
  onlyNumbersOrLettersError: 'It must contain only numbers or letters',
  connectionErrorMessage: 'Please check your internet connection',
  imageTooBigErrorMessage:
    'Uploaded file is too big. Please try to upload another image.',
  badRequestMessage: 'Bad request',
  notFoundError: 'The action cannot be executed, the resource was not found',
  FirstHalf: 'First half',
  HalfTime: 'Half-time',
  HalfTimeShort: 'HT',
  SecondHalf: 'Second Half',
  'Full-time': 'Full-time',
  Postponed: 'Postponed',
  PostponedShort: 'Post.',
  Abandoned: 'Abandoned',
  'Delayed - delayed start': 'Delayed start',
  playersToBreakFree: 'You must select {0} player(s) to break free',
  breakingFreePlayer: 'Player to break free',
  youMustSelectPlayersMessage: 'You must select {0} players(s)',
  playersExcessMessage: `If this exchange takes place, you would have {0} players on your team, so you must select {1} player(s) to be left free(s) at the time of the exchange.`,
  request_not_enough_players:
    'The request team cannot get less than 11 players',
  request_too_many_players: 'The request team cannot get more than 11 players',
  request_doesnt_contain_player:
    'The request team  already does not have one or more of the offered players',
  receive_not_enough_players:
    'The receiver team cannot get less than 11 players',
  receive_too_many_players: 'The receiver team cannot get more than 16 players',
  receive_doesnt_contain_player:
    'The receiver team already does not have one or more of the demanded players',
  locked_players:
    'Some player or players on the exchange is locked due to real life league activity',
  privateLeague: 'Private league',
  publicLeague: 'Public league',
  or: 'or',
  joinCreateLeagueModal: {
    tooltipTextPrivate:
      'Create a league with friends and acquaintances. Only people that you invite will be part of the league.',
    tooltipTextPublic:
      'Create an open league. Every joga fantasy user will be able to join your league.',
  },
  joinLeagueModal: {
    tooltipTextPrivate:
      'You can only join if you have received an invitation code',
    tooltipTextPublic: 'Join any of our open available leagues',
  },
  PublicLeagues: {
    title: 'Public leagues',
    bottomParagraph: {
      text: 'If you do not want to be part of any of these leagues you can enter our ',
      waitingList: 'waitlist',
    },
    tableHeader: {
      name: 'Name',
      commissioner: 'Commissioner',
      teams: 'Teams',
      draft: 'Draft',
    },
    noData: {
      text1: 'Currently there are no public leagues available. You can ',
      waitingList: 'join our waitlist',
      text2:
        ' and you will be added automatically to a new league as soon as we have 10 available users. You can also ',
      createLeague: 'create a league',
      text3: ' and wait for other users to join.',
    },
  },
  waitingListModal: {
    title: 'Waiting list',
    content:
      'You has been added to the waiting list of public leagues. When there is 10 members you will be added automatically to a new league.',
    table: {
      header: {
        user: 'User',
        username: 'Username',
        commissioner: 'Commissioner',
        country: 'Country',
        favoriteTeam: 'Favorite team',
        joindDate: 'Join date',
        guests: 'Guest',
      },
    },
    error: 'You have to select at least one guest and one commissioner',
  },
  join: 'Join',
  maxParticipats: {
    label: 'Maximum number of participants',
    placeholder: 'Select number of participants',
  },
  leagueDuration: {
    label: 'Duration of the league',
    placeholder: 'Select the duration of the league',
    placeholderAll: 'All season',
  },
  tradeSuccess: 'You have added {0} and dropped {1} successfully',
  playerTradedAddedToWaiver:
    'You have added a waiver claim for {0}. If successful, {1} will be dropped',
  playerAddedToWaiver: 'You have added a waiver claim for {0}',
  playerRemoved: 'You have dropped {0} successfully',
  playerAdded: 'You have added {0} successfully',
  playerAddedToFavorites: 'You have added {0} to your favorites list',
  playerRemovedFromFavorites: 'You have removed {0} from your favorites list',
  leagueCreated: 'League created successfully',
  Draft: {
    showAllSelectedPlayers: 'Show only unselected players',
  },
  draftMenu: 'Draft',
  legend: 'Legend',
  daysOfWeek: {
    DOM: 'Sun.',
    LUN: 'Mon.',
    MAR: 'Tue.',
    MIER: 'Wed.',
    JUE: 'Thu.',
    VIE: 'Fri.',
    SAB: 'Sat.',
  },
  daysOfWeekFull: {
    DOM: 'sunday',
    LUN: 'monday',
    MAR: 'tuesday',
    MIER: 'wednesday',
    JUE: 'thursday',
    VIE: 'friday',
    SAB: 'saturday',
  },
  months: {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  },
  position_abbreviation: {
    goalkeeper: 'GK',
    defender: 'Def.',
    midfielder: 'Mid.',
    attacker: 'Att.',
  },
  Career: 'Career',
  Injuries: 'Injuries',
  Injury: 'Injury',
  Date: 'Date',
  Transferences: 'Transferences',
  Club: 'Club',
  LocalCup: 'Local cup',
  Duration: 'Duration',
  InternationalCup: 'International cup',
  NationalTeam: 'National team',
  Competition: 'Competition',
  Journey: 'Journey',
  Res: 'Res',
  goal_against: 'Goal against',
  Actual: 'Current',
  From: 'From',
  To: 'To',
  Transfer: 'Transfer',
  Free: 'Free',
  ofWord: ' of ',
  VideoPromoDesktop: 'https://youtu.be/CXQfVY3r9g8',
  VideoPromoMobile: 'ttps://youtu.be/fde1qdpBBXs',
  VideoCreateLeague: 'https://youtube.com/shorts/Utm53ApVADI?feature=share',
  VideoInviteUsersLeague:
    'https://youtube.com/shorts/LoXk_i6TdeU?feature=share',
};

export default en;
