import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getJsonFromUrl } from '../utilities';

const HomePage = React.lazy(() => import('./home/Home'));
const Dashboard = React.lazy(() => import('./home/Dashboard'));
const LeagueDetails = React.lazy(() => import('./home/LeagueDetails'));
const CreateNewPublicLeague = React.lazy(() =>
  import('./home/CreateNewPublicLeague')
);
const CreateNewPrivateLeague = React.lazy(() =>
  import('./home/CreateNewPrivateLeague')
);
const PublicLeagues = React.lazy(() => import('./league/public-leagues'));
const CreateTeam = React.lazy(() => import('./home/CreateTeam'));
const EditTeam = React.lazy(() => import('./league/EditTeam'));
const EditLeague = React.lazy(() => import('./league/EditLeague'));
const DraftDetails = React.lazy(() => import('./home/DraftDetails'));
const MyTeam = React.lazy(() => import('./home/MyTeam'));
const Matches = React.lazy(() => import('./home/Matches'));
const Classification = React.lazy(() => import('./home/Classification'));
const Messages = React.lazy(() => import('./league/Messages'));
const TopicsMessages = React.lazy(() => import('./league/TopicsMessages'));
const Transactions = React.lazy(() => import('./home/Transactions'));
const Calendar = React.lazy(() => import('./home/Calendar'));
const News = React.lazy(() => import('./home/News'));
const MatchDetails = React.lazy(() => import('./home/MatchDetails'));
const TradeRequest = React.lazy(() => import('./home/TradeRequest'));
const Building = React.lazy(() => import('./Building'));
const Team = React.lazy(() => import('./home/Team'));

/* ADMIN */
const Notices = React.lazy(() => import('./admin/Notices'));
const WaitingList = React.lazy(() => import('./admin/WaitingList'));
const Leagues = React.lazy(() => import('./admin/Leagues'));
const WaitingListNewPublicLeague = React.lazy(() =>
  import('./admin/CreateNewPublicLeague')
);
const LeaguePlayers = React.lazy(() => import('./league/LeaguePlayers'));
const NewsDetails = React.lazy(() => import('./home/NewsDetails'));
const Contact = React.lazy(() => import('./home/Contact'));
const LeagueTransactions = React.lazy(() =>
  import('./league/LeagueTransactions')
);
const PlayerDetailed = React.lazy(() => import('./league/PlayerDetailed'));
const WaiverRequest = React.lazy(() => import('./league/WaiverRequest'));

const Routes = ({ isAuthenticated }) => (
  <Router>
    <div style={{ backgroundColor: '#ffffff' }}>
      <React.Suspense fallback={<></>}>
        <Route
          exact
          path="/recovery-password/:token/:lang"
          component={HomePage}
        />
        <Route exact path="/confirm-email/:token/:lang" component={HomePage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/building" component={Building} />
        <UnloggedInRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/"
          component={HomePage}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/league-details"
          component={LeagueDetails}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/league-details/:id"
          component={LeagueDetails}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/public-leagues"
          component={PublicLeagues}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/league-transactions/:id"
          component={LeagueTransactions}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/edit-league/:id"
          component={EditLeague}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/create-new-private-league"
          component={CreateNewPrivateLeague}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/create-new-public-league"
          component={CreateNewPublicLeague}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/create-team"
          component={CreateTeam}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/edit-team/:id"
          component={EditTeam}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/league/:leagueId/my-team/:id"
          component={MyTeam}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/league/:leagueId/team/:id"
          component={Team}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/edit-league"
          component={EditLeague}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/draft-details/:id"
          component={DraftDetails}
          isAuthenticated={isAuthenticated}
          profileComplete
        />

        <PrivateRoute
          exact
          path="/transactions"
          component={Transactions}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/league-messages/:id"
          component={Messages}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/waiting-list"
          component={WaitingList}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/leagues"
          component={Leagues}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/waiting-list/create-new-public-league"
          component={WaitingListNewPublicLeague}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <PrivateRoute
          exact
          path="/notices/:page"
          component={Notices}
          isAuthenticated={isAuthenticated}
          profileComplete
        />
        <Route exact path="/notice-details/:id" component={NewsDetails} />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/matches/:id"
          component={Matches}
        />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/matches/:id/selected/:selectedMatch"
          component={Matches}
        />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/league-players/:id"
          component={LeaguePlayers}
        />

        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/league/:leagueId/player/:id"
          component={PlayerDetailed}
        />

        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/calendar/:id"
          component={Calendar}
        />

        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/classification"
          component={Classification}
        />

        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/league/:leagueId/league-topics/:id"
          component={TopicsMessages}
        />
        <Route exact path="/news/:page" component={News} />
        <Route exact path="/match-details/:id" component={MatchDetails} />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/league/:leagueId/trade-request/:id"
          component={TradeRequest}
        />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          profileComplete
          exact
          path="/league/:leagueId/waiver-request/:id"
          component={WaiverRequest}
        />
      </React.Suspense>
    </div>
  </Router>
);

Routes.propTypes = {
  isAuthenticated: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.accessStore.user && state.accessStore.user.id,
  };
}
export default connect(mapStateToProps)(Routes);

const UnloggedInRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const { confirm_invitation: confirmInvitation } = getJsonFromUrl(
        props.location
      );
      if (isAuthenticated) {
        if (rest.path.indexOf('/dashboard') === -1) {
          return <Redirect exact to={`/dashboard${props.location.search}`} />;
        }
        return <Component {...props} />;
      }
      if (confirmInvitation) {
        localStorage.setItem('confirmInvitation', confirmInvitation);
      }
      if (rest.path === '/league-details/:id') {
        localStorage.setItem('inviteOn', props.location.pathname);
        return <Redirect exact to="/" />;
      }
      return <Component {...props} />;
    }}
  />
);

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  routeToRedirect,
  profileComplete,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) {
        if (profileComplete) {
          return <Component {...props} />;
        }
        if (rest.path !== '/register') {
          return <Redirect exact to="/register" />;
        }
        return <Component {...props} />;
      }
      localStorage.setItem('redirectAfterLogin', props.location.pathname);
      return <Redirect exact to="/" />;
    }}
  />
);
