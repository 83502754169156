import API from '../../api';
import * as types from './types';

export const setCurrentTradeRequest = (data) => ({
  type: types.SET_CURRENT_TRADE_REQUEST,
  payload: data,
});
export const setCurrentPlayer = (payload) => ({
  type: types.SET_CURRENT_PLAYER,
  payload,
});
export const setCurrentTeam = (team) => ({
  type: types.SET_CURRENT_TEAM,
  payload: team,
});
export const setCurrentLeague = (data) => ({
  type: types.SET_CURRENT_LEAGUE,
  payload: data,
});
export const appendChatMessage = (data) => ({
  type: types.APPEND_CHAT_MESSAGE,
  payload: data,
});

export const getPlayerDetails = (leagueId, playerId) => async (dispatch) => {
  await API.get(
    `/api-external/api-players/${playerId}?league=${leagueId}`
  ).then((response) => {
    console.log(response);
    if (response.data && response.data.id) {
      dispatch(setCurrentPlayer(response.data));
    }
  });
};

export const confirmLeagueInvitation =
  (token, onSuccess) => async (dispatch) => {
    await API.get(`/leagues/confirm-invitation/${token}`).then((response) => {
      if (response.code === 200 && response.data) {
        dispatch({
          type: types.GET_CURRENT_INVITATION_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({ type: types.GET_CURRENT_INVITATION_SUCCESS, payload: null });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };

export const getLeagueChatHistory = (leagueId, onSuccess) => (dispatch) => {
  API.get(`/leagues/${leagueId}/messages`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({ type: types.GET_LEAGUE_CHAT_HISTORY, payload: response.data });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getParameter = (keyValue, onSuccess) => (dispatch) => {
  API.get(`/parameters?key=${keyValue}`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({
        type: types.GET_PARAMETER,
        payload: response.data[0].value,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getPublicLeagues =
  (onSuccess, leagueStatus) => async (dispatch) => {
    await API.get(
      `/leagues?league_type=public${
        leagueStatus && leagueStatus !== '' ? `&status=${leagueStatus}` : ''
      }`
    ).then((response) => {
      if (response.data) {
        dispatch({
          type: types.GET_PUBLIC_LEAGUES,
          payload: response.data,
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };

export const joinPublicLeague = (leagueId, onSuccess) => async (dispatch) => {
  await API.post(`/leagues/join/public`, {
    league: leagueId,
  }).then((response) => {
    if (response.data) {
      dispatch({
        type: types.JOIN_PUBLIC_LEAGUE,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getWaitingList = (onSuccess) => async (dispatch) => {
  await API.get(`/waiting_list`).then((response) => {
    if (response.data) {
      dispatch({
        type: types.GET_WAITING_LIST,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const addUserToWaitingList = (onSuccess) => async (dispatch) => {
  await API.post(`/waiting_list`, {}).then((response) => {
    if (response.data) {
      dispatch({
        type: types.ADD_USER_TO_WAITING_LIST,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getPlayersFromTeam = (teamId, onSuccess) => (dispatch) => {
  API.get(`/team-players/${teamId}`)
    .then((response) => {
      if (response.data && response.data.id) {
        dispatch({
          type: types.GET_PLAYERS_FROM_TEAM,
          payload: response.data,
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getLeagueDetails =
  (
    leagueId,
    username,
    pageName = '',
    currentTeam = null,
    team = null,
    onSuccess
  ) =>
  (dispatch) => {
    dispatch({ type: types.GET_CURRENT_LEAGUE, leagueId });
    let url = `/league-details/league/${leagueId}?page=${pageName}`;
    if (pageName === 'Teams' && currentTeam && team) {
      url += `&currentTeam=${currentTeam}&team=${team}`;
    }
    API.get(url)
      .then((response) => {
        if (response.data && response.data.id) {
          dispatch({
            type: types.SET_CURRENT_LEAGUE,
            payload: response.data,
            username,
          });
        }
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: types.GET_CURRENT_LEAGUE_ERROR });
      });
  };

export const getLeaguesAdmin =
  (page = 1, limit = 10, onSuccess) =>
  async (dispatch) => {
    API.get(`/league-details/leagues?page=${page}&limit=${limit}`).then(
      (response) => {
        if (response.data) {
          dispatch({
            type: types.GET_LEAGUES_ADMIN,
            payload: response.data,
          });
        }
        if (onSuccess) {
          onSuccess(response.data);
        }
      }
    );
  };

export const joinToLeague = (userId, data, onSuccess) => () => {
  API.post(`/users/${userId}/join-league`, data).then((response) => {
    console.log(response);
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const createLeague = (data, onSuccess) => () => {
  API.post(`/leagues`, data).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const editLeague = (leagueId, data, onSuccess) => () => {
  API.put(`/leagues/${leagueId}`, data).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const deleteLeague = (leagueId, onSuccess) => () => {
  API.delete(`/leagues/${leagueId}`).then((response) => {
    console.log(response);
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const uploadLeagueImage = (leagueId, data, onSuccess) => () => {
  API.post(`/leagues/${leagueId}`, data).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

/* export const createTeam = (leagueId, data, onSuccess) => () => {
  API.post(`/leagues/${leagueId}/league-teams`, data).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
}; */

export const createTeam = (leagueId, data, onSuccess) => () => {
  const form = new FormData();
  form.append('team_name', data.team_name);
  form.append('image', data.image);
  form.append('user', data.user);
  form.append('_method', 'POST');
  form.append('enctype', 'multipart/form-data');
  API.post(`/leagues/${leagueId}/league-teams`, form).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

/* export const editTeam = (leagueId, teamId, data, onSuccess) => () => {
  const form = {
    team_name: data.name,
  };
  API.put(`/leagues/${leagueId}/league-teams/${teamId}`, form).then(
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
}; */

export const editTeam = (leagueId, teamId, data, onSuccess) => () => {
  let form;
  if (data.image.name) {
    form = new FormData();
    form.append('team_name', data.name);
    form.append('image', data.image);
    form.append('user', data.user);
    form.append('_method', 'PUT');
    form.append('enctype', 'multipart/form-data');
    API.post(`/leagues/${leagueId}/league-teams/${teamId}`, form).then(
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      }
    );
  } else {
    form = {
      team_name: data.name,
    };
    API.put(`/leagues/${leagueId}/league-teams/${teamId}`, form).then(
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      }
    );
  }
};

export const updateTeamImage = (leagueId, teamId, data, onSuccess) => () => {
  const form = new FormData();
  form.append('image', data.image);
  form.append('user', data.user);
  form.append('_method', 'PUT');
  form.append('enctype', 'multipart/form-data');
  API.post(`/leagues/${leagueId}/league-teams/${teamId}`, form).then(
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};

export const uploadTeamImage = (leagueId, data, onSuccess) => () => {
  const form = new FormData();
  form.append('image', data);
  form.append('_method', 'PUT');
  API.post(`/leagues/${leagueId}/league-teams`, form).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const myTeams = (userId, onSuccess) => (dispatch) => {
  API.get(`/home/league-teams?sort=-created_at`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({ type: types.GET_MY_TEAMS_SUCCESS, payload: response.data });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getMyMatches = (userId, onSuccess) => (dispatch) => {
  API.get(`/users/${userId}/league-matches/?is_current=true`).then(
    (response) => {
      if (Array.isArray(response.data)) {
        dispatch({
          type: types.GET_MY_MATCHES_SUCCESS,
          payload: response.data,
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};

export const getLeagueTeams = (leagueId, onSuccess) => () => {
  API.get(`/leagues/${leagueId}/league-teams`).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getMatchups = (leagueId, onSuccess) => (dispatch) => {
  dispatch({ type: types.GET_LEAGUE_MATCHUPS, leagueId });
  API.get(
    `/league-details/league/${leagueId}/matches?is_current=true&page=matchups`
  )
    .then((response) => {
      if (Array.isArray(response.data)) {
        dispatch({
          type: types.GET_LEAGUE_MATCHUPS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({ type: types.GET_LEAGUE_MATCHUPS_ERROR });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    })
    .catch(() => {
      dispatch({ type: types.GET_LEAGUE_MATCHUPS_ERROR });
    });
};

export const getLeagueMatches =
  (leagueId, onSuccess, query = '') =>
  (dispatch) => {
    API.get(`/league-details/league/${leagueId}/matches${query}`).then(
      (response) => {
        console.log('ESTE FUE QUE SE EJECUTO', query);
        if (response && Array.isArray(response.data)) {
          dispatch({
            type: types.GET_LEAGUE_MATCHES_SUCCESS,
            payload: response.data,
          });
          if (onSuccess) {
            onSuccess(response);
          }
        }
      }
    );
  };

export const getLeagueCalendar =
  (leagueId, onSuccess, query = '') =>
  (dispatch) => {
    API.get(`/calendar/leagues/${leagueId}/league-matches${query}`).then(
      (response) => {
        if (Array.isArray(response.data)) {
          dispatch({
            type: types.GET_LEAGUE_CALENDAR_SUCCESS,
            payload: response.data,
          });
          if (onSuccess) {
            onSuccess(response);
          }
        }
      }
    );
  };

export const getTeamDetail =
  (leagueId, teamId, filter, onSuccess) => (dispatch) => {
    let hasFilter = '';
    if (filter) {
      hasFilter = `${filter}`;
    }

    API.get(`/leagues/${leagueId}/league-teams/${teamId}${hasFilter}`).then(
      (response) => {
        console.log('and team details: ', response);
        if (response.data && response.data.id) {
          const currentTeamStarters = {
            Attackers: [],
            Defenders: [],
            Midfielders: [],
            Goalkeepers: [],
          };
          if (
            response.data &&
            Array.isArray(response.data.league_team_players)
          ) {
            response.data.league_team_players.forEach((player) => {
              if (
                player.position_type === 'titular' &&
                currentTeamStarters[`${player.position}s`]
              ) {
                currentTeamStarters[`${player.position}s`].push(player);
              }
            });
          }
          dispatch({
            type: types.SET_CURRENT_TEAM,
            payload: response.data,
            currentTeamStarters,
          });
        }
        if (onSuccess) {
          onSuccess(response);
        }
      }
    );
  };

export const createLeagueMatches = (leagueId) => () => {
  API.post(`/leagues/${leagueId}/league-matches`).then((response) => {
    console.log(response);
    // if (Array.isArray(response.data)) {
    //   dispatch({ type: types.GET_MY_TEAMS_SUCCESS, payload: response.data });
    // }
    // if (onSuccess) {
    //   onSuccess(response);
    // }
  });
};

export const getTournamentStage = () => (dispatch) => {
  API.get(`/parameters/tournament_current_week`).then((response) => {
    if (response.data && response.data.value) {
      dispatch({
        type: types.GET_TOURNAMENT_STAGE_SUCCESS,
        payload: response.data,
      });
    }
  });
};

export const editTournamentStage = (date, onSuccess) => (dispatch) => {
  API.put(`/parameters/tournament_current_week`, date).then((response) => {
    if (response.data && response.data.value) {
      dispatch({
        type: types.GET_TOURNAMENT_STAGE_SUCCESS,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getAlignments = () => (dispatch) => {
  API.get(`/alignments`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({ type: types.GET_ALIGNMENTS_SUCCESS, payload: response.data });
    }
  });
};

export const setStartersPlayers = (leagueId, teamId, data, onSuccess) => () => {
  API.put(
    `/leagues/${leagueId}/league-teams/${teamId}/league-team-players/titular`,
    data
  ).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getCountries = (onSuccess) => (dispatch) => {
  API.get(`/country`).then((response) => {
    if (response.code === 200 && response.data) {
      dispatch({
        type: types.GET_COUNTRIES,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};
