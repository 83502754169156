export const GET_MY_TEAMS_SUCCESS = 'Joga/draft/GET_MY_TEAMS_SUCCESS';
export const GET_MY_MATCHES_SUCCESS = 'Joga/draft/GET_MY_MATCHES_SUCCESS';
export const SET_CURRENT_TEAM = 'Joga/draft/SET_CURRENT_TEAM';
export const GET_CURRENT_INVITATION_SUCCESS =
  'Joga/draft/GET_CURRENT_INVITATION_SUCCESS';
export const GET_CURRENT_LEAGUE = 'Joga/draft/GET_CURRENT_LEAGUE';
export const GET_CURRENT_LEAGUE_ERROR = 'Joga/draft/GET_CURRENT_LEAGUE_ERROR';
export const GET_LEAGUES_ADMIN = 'Joga/draft/GET_LEAGUES_ADMIN';
export const SET_CURRENT_LEAGUE = 'Joga/draft/SET_CURRENT_LEAGUE';
export const GET_LEAGUE_CHAT_HISTORY = 'Joga/draft/GET_LEAGUE_CHAT_HISTORY';
export const APPEND_CHAT_MESSAGE = 'Joga/draft/APPEND_CHAT_MESSAGE';
export const GET_LEAGUE_MATCHES_SUCCESS =
  'Joga/draft/GET_LEAGUE_MATCHES_SUCCESS';
export const GET_LEAGUE_CALENDAR_SUCCESS =
  'Joga/draft/GET_LEAGUE_CALENDAR_SUCCESS';
export const GET_LEAGUE_MATCHUPS = 'Joga/draft/GET_LEAGUE_MATCHUPS';
export const GET_LEAGUE_MATCHUPS_SUCCESS =
  'Joga/draft/GET_LEAGUE_MATCHUPS_SUCCESS';
export const GET_LEAGUE_MATCHUPS_ERROR = 'Joga/draft/GET_LEAGUE_MATCHUPS_ERROR';
export const GET_TOURNAMENT_STAGE_SUCCESS =
  'Joga/draft/GET_TOURNAMENT_STAGE_SUCCESS';
export const GET_ALIGNMENTS_SUCCESS = 'Joga/draft/GET_ALIGNMENTS_SUCCESS';
export const SET_CURRENT_TEAM_PLAYERS = 'Joga/draft/SET_CURRENT_TEAM_PLAYERS';
export const SET_CURRENT_TRADE_REQUEST = 'Joga/draft/SET_CURRENT_TRADE_REQUEST';
export const SET_CURRENT_PLAYER = 'Joga/draft/SET_CURRENT_PLAYER';
export const GET_PUBLIC_LEAGUES = 'Joga/draft/GET_PUBLIC_LEAGUES';
export const ADD_USER_TO_WAITING_LIST = 'Joga/draft/ADD_USER_TO_WAITING_LIST';
export const GET_WAITING_LIST = 'Joga/draft/GET_WAITING_LIST';
export const JOIN_PUBLIC_LEAGUE = 'Joga/draft/JOIN_PUBLIC_LEAGUE';
export const SET_GUESTS = 'Joga/transactions/SET_GUESTS';
export const SET_COMMISIONER = 'Joga/transactions/SET_COMMISIONER';
export const GET_PLAYERS_FROM_TEAM = 'Joga/transactions/GET_PLAYERS_FROM_TEAM';
export const GET_PARAMETER = 'joga/draft/GET_PARAMETER';
export const GET_COUNTRIES = 'joga/home/GET_COUNTRIES';
